
import { Tag } from 'momai'
import { computed, defineComponent, ref, watch } from 'vue'
import { courseSeriesTags, teacherType } from '@/states/tags'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true
    },
    tagtype: {
      type: String,
      default: '0'
    },
    title: {
      type: String,
      default: '标签'
    }
  },
  setup: (props, { emit }) => {
    const showPicker = ref(false)
    const tagIds = computed(() => props.value.split(',').map(str => Number(str)))
    const checkedTags = ref<Tag[]>([])
    const showTags = ref<Tag[]>([])
    const setCheckedTags = () => {
      if(props.tagtype === '3'){
        showTags.value = teacherType.value
        checkedTags.value = teacherType.value.filter(t => tagIds.value.some(id => id === t.id))
      }else{
        showTags.value = courseSeriesTags.value
        checkedTags.value = courseSeriesTags.value.filter(t => tagIds.value.some(id => id === t.id))
      }

    }
    setCheckedTags()
    watch(checkedTags, newCheckedTags => {
      emit('update:value', newCheckedTags.map(t => t.id).join(','))
    })
    watch(() => props.value, setCheckedTags)
    return {
      showPicker,
      checkedTags,
      courseSeriesTags,
      showTags
    }
  }
})
